// 업무상태에 대한 필터 함수를 정의한다.
export default (value) => {
  if (!value) return ''
  const statusArray = [
    { text: '미발행', value: 1 },
    { text: '세금계산서', value: 2 },
    { text: '현금영수증', value: 3 },
    { text: '카드결제', value: 4 }
  ]
  return statusArray.find(s => s.value === value).text
}
