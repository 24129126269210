<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :close-on-click="false"
    :nudge-width="options.width"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    origin="center center"
    transition="scale-transition"
  >
    <v-card
      class="pa-3"
      max-width="650"
    >
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" class="pa-3">
          <v-select
            v-model="form.status"
            :items="statusSelect"
            item-text="text"
            item-value="value"
            dense
            label="첨부파일구분"
            flat
            hide-details
            solo-inverted
            style="max-width: 200px;font-size: 0.785rem !important"
            @change="changeStatus"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pr-5">
          <v-text-field
            ref="txtAddress"
            v-model.trim="form.str2"
            :rules="[rules.required, rules.limitAddress]"
            label="주소"
            placeholder="https://www.google.com"
            :maxlength="addressLimit"
            required
            autofocus
          >
            <v-icon slot="prepend">mdi-link-plus</v-icon>
          </v-text-field>
        </v-col>
        <v-col cols="12" class="pr-5">
          <v-text-field
            ref="txtComment"
            v-model.trim="form.str1"
            :rules="[rules.required, rules.limitComment]"
            label="링크 텍스트"
            placeholder="구글 바로가기"
            :maxlength="commentLimit"
            required
          >
            <v-icon slot="prepend">mdi-format-title</v-icon>
          </v-text-field>
        </v-col>
        <v-col cols="12" class="pa-3">
          <v-btn small text color="primary"
            @click="agree"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>저장
          </v-btn>
          <v-btn small text color="error"
            @click="cancel"
          >
            <v-icon small class="mr-2">mdi-cancel</v-icon>취소
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    resolve: null, // 이 방법이 너무 참신하다!
    reject: null,
    options: {
      color: 'primary',
      width: 400
    },
    showMenu: false,
    x: 0,
    y: 0,
    // 구분: 폼
    form: {
      id: 0,
      teamId: 3, // !! 불변 - 물품구매팀이 초기값
      type: 38, // !! 불변 - 유형(공유링크)
      depth: 3, // !! 불변
      pId: 0, // 부모 아이디
      pType: 0, // !! 부모타입 - 구매내역(47)/정산내역(48) 중 1
      pInfo: '', // 부모 정보위치
      ppId: 0, // 부모의 부모 아이디
      ppType: 46, // !! 불변 - 조부모는 거래처로 픽스
      ppInfo: '', // 조부모 정보위치
      subtype: 1, // 등록을 제외한 업무는 1로 세팅(등록은 자동입력데이터임)
      gubun1: '공유링크',
      str1: '', // 링크 텍스트
      str2: '', // 주소
      status: 1, // 첨부파일구분 [2021.3.9]
      gubun2: '' // 첨부파일구분 [2021.3.9]
    },
    // 구분: 주소 글자수 제한
    addressLimit: 255,
    // 구분: 링크텍스트 글자수 제한
    commentLimit: 100,
    // 구분: 첨부파일구분 셀렉트 - !! [2021.3.9]
    statusSelect: []
  }),

  computed: {
    // data 에서는 this를 쓸 수 없으므로 computed 에서
    rules () {
      return {
        required: value => !!value || '입력값은 필수입니다',
        limitAddress: value => value.length <= this.addressLimit || `글자수는 ${this.addressLimit}자 내외로 해야 합니다`,
        limitComment: value => value.length <= this.commentLimit || `글자수는 ${this.commentLimit}자 내외로 해야 합니다`
      }
    }
  },

  watch: {
    'form.str1': { // 링크 텍스트
      handler: function (val, oldVal) {
        // 한글 글자수 제한을 넘지 못하게 깔끔하게 막는 방법
        if (val.length > this.commentLimit) {
          this.form.str1 = oldVal
          this.$refs.txtComment.lazyValue = oldVal
        }
      }
    },
    'form.str2': { // 주소
      handler: function (val, oldVal) {
        // 한글 글자수 제한을 넘지 못하게 깔끔하게 막는 방법
        if (val.length > this.addressLimit) {
          this.form.str2 = oldVal
          this.$refs.txtAddress.lazyValue = oldVal
        }
      }
    }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화
    async init () {
      try {
        this.$refs.txtAddress.resetValidation() // 주소
        this.$refs.txtComment.resetValidation() // 링크 텍스트

        this.form.id = 0
        this.form.teamId = 3 // !! 불변 - 물품구매팀이 초기값
        this.form.pId = 0
        this.form.pType = 0
        this.form.pInfo = ''
        this.form.ppId = 0
        this.form.ppType = 46 // !! 불변 - 조부모는 거래처로 픽스
        this.form.ppInfo = ''
        this.form.str1 = ''
        this.form.str2 = ''
        // !! [2021.3.9]
        this.form.status = 1 // 첨부파일구분 [2021.3.9]
        this.form.gubun2 = '' // 첨부파일구분 [2021.3.9]
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 메뉴 팝업을 띄우는 함수
    async show (evt) {
      try {
        if (!this.showMenu) { // !! 안전장치 - 이미 열려있으면 다시 열리지 않게 한다.
          evt.preventDefault()
          this.showMenu = false
          this.x = evt.clientX
          this.y = evt.clientY

          this.$nextTick(() => {
            this.showMenu = true
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // data setting
    async setData (item) {
      try {
        if (!item) throw new Error(`잘못된 인자 형식입니다.`)

        // !! 폼값 세팅 : 나머지 정보를 입력한다(부모와 조부의 정보)
        this.form.pId = item.id // 부모 아이디
        this.form.pType = item.type // 부모 타입 - 구매내역(47)/정산내역(48)
        this.form.ppId = item.pId // 조부모(거래처) 아이디
        this.form.ppInfo = item.pInfo // 조부모(거래처) 정보위치

        // !![2021.3.11] pInfo 추가 - 구매내역(47)/정산내역(48)
        this.form.pInfo = item.type === 47 ? '구매내역' : '정산내역'

        // !! [2021.3.9 추가] 카테고리 셀렉트
        this.setCates('24').then(({ cates }) => {
          if (cates && cates.length > 0) {
            this.statusSelect.push(...cates.map(c => ({ text: c.gubun1, value: c.num })))
            // 관리상태의 초기값 지정
            this.form.status = this.statusSelect[0].value
            this.changeStatus()
          }
        })

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    async agree () {
      try {
        if (!this.$refs.txtAddress.validate()) {
          this.$refs.txtAddress.focus() // 자연스럽게 보이기 위해 포커싱
          throw new Error(`입력값을 확인하세요.`)
        }
        if (!this.$refs.txtComment.validate()) {
          this.$refs.txtComment.focus() // 자연스럽게 보이기 위해 포커싱
          throw new Error(`입력값을 확인하세요.`)
        }

        // !! DB 처리(입력/수정)
        const { data } = await this.$axios.post(`lawork/lwc/setLinkShare`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        await this.init()
        this.resolve(true)
        this.showMenu = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    async cancel () {
      try {
        await this.init()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.showMenu = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 카테고리 셀렉트 변경 이벤트 핸들러
    async changeStatus () {
      try {
        // this.form.gubun2 에 카테고리 문자열 값을 매칭한다
        this.form.gubun2 = this.statusSelect.find(s => s.value === this.form.status).text
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [util함수] 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
