<template>
  <v-container fluid class="pa-0 ma-0">

    <!-- 상단 툴바 -->
    <v-toolbar dense flat>
      <v-btn text small @click="goList">
        <v-icon small>mdi-view-list</v-icon>
        목록
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        text
        small
        color="success"
        @click="editArticle">
        <v-icon small>mdi-pencil</v-icon>
        수정
      </v-btn>
      <v-btn
        text
        small
        color="error"
        @click="deleteArticle">
        <v-icon small>mdi-trash-can-outline</v-icon>
        삭제
      </v-btn>
      <!-- <v-btn text small>
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn> -->
    </v-toolbar>

    <v-divider></v-divider>

    <!-- 컨텐츠 영역 -->
    <v-card
      tile
      :elevation="0"
    >
      <v-row
        no-gutters
      >
        <v-col v-if="!isId" cols="12">
          <v-alert
            prominent
            type="warning"
            class="ma-4 pa-10"
          >존재하지 않는 데이터 입니다</v-alert>
        </v-col>
        <v-col v-else cols="12">
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
            >
              <div
                class="pa-2"
              >
                <v-icon small
                  v-if="article.isStar"
                  class="pb-1"
                  color="amber darken-1"
                >mdi-star</v-icon>
                <span class="mx-1 text-h6 primary--text">
                  {{ article.name }}
                </span>
                <span style="font-size: 1.2rem;font-weight: 400;">
                  {{ article.coNum ? ` (${article.coNum})` : '' }}
                </span>
              </div>
              <v-divider></v-divider>
            </v-col>

            <!-- 좌측 컨텐츠 시작 --->
            <v-col
              cols="12"
              md="7"
              lg="7"
              xl="7"
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">대표자 | 담당자</span>
                      <span style="font-size: 0.9rem;font-weight: 500;">{{ viewCompanyMan }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">주소</span>
                      <span class="text-body-2">{{ article.address }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">M</span>
                      <span class="text-body-2">{{ article.hp }}</span>
                      <!-- <v-icon small
                        v-show="article.hp"
                        class="ml-1"
                        color="primary lighten-2"
                        @click="dummy"
                      >mdi-cellphone-iphone</v-icon> -->
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">T</span>
                      <span class="text-body-2">{{ article.tel }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">F</span>
                      <span class="text-body-2">{{ article.fax }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 py-1">
                      <v-hover v-slot:default="{ hover }">
                        <div>
                          <span class="text-body-2 grey--text mr-3">E</span>
                          <span class="text-body-2">{{ article.email }}</span>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="article.email"
                                v-on="on"
                                class="ml-1"
                                color="primary"
                                :style="hover ? 'font-size: 1.2rem;' : 'font-size: 0.9rem;'"
                                @click="writeEmail(article.email)"
                              >
                                {{ hover ? 'mdi-email' : 'mdi-email-outline' }}
                              </v-icon>
                            </template>
                            <span>메일보내기</span>
                          </v-tooltip>
                        </div>
                      </v-hover>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <v-hover v-slot:default="{ hover }">
                        <div>
                          <span class="text-body-2 grey--text mr-3">H</span>
                          <span class="text-body-2">{{ article.homepage }}</span>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="article.homepage"
                                v-on="on"
                                class="ml-1"
                                color="primary"
                                :style="hover ? 'font-size: 1.3rem;' : 'font-size: 1.0rem;'"
                                @click="goSite(article.homepage)"
                              >
                                {{ hover ? 'mdi-home' : 'mdi-home-outline' }}
                              </v-icon>
                            </template>
                            <span>홈페이지 바로가기</span>
                          </v-tooltip>
                        </div>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-col>

                <v-divider vertical class="ma-0 pa-0"></v-divider>

                <!-- 좌측 상단 > 우측 -->
                <v-col>
                  <!-- <v-row no-gutters class="ma-0 pa-1">
                    <v-col class="ma-0 pa-0">
                      <div class="text-body-2 px-1 pt-1">
                        <span class="grey--text text--darken-1 mr-1">담당부서</span>
                        <span :class="`${article.teamColor}`">물품구매관리팀</span>
                      </div>
                      <div class="px-1 pt-1">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">취급품목</span>
                        <span class="text-body-2 primary--text" style="font-weight: 600;">
                          {{ article.items }}
                        </span>
                      </div>
                      <div class="px-1 pt-1">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">계좌번호</span>
                        <span class="text-body-2">
                          {{ article.bankAccount }}
                        </span>
                      </div>
                    </v-col>
                  </v-row> -->
                  <v-row no-gutters class="text-body-2 ma-0 pa-0">
                    <v-col class="ma-0 px-2 py-1">
                      <span class="grey--text text--darken-1 mr-3">담당부서</span>
                      <span :class="`${article.teamColor}`">물품구매관리팀</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="text-body-2 ma-0 pa-0">
                    <v-col class="ma-0 px-2 py-1">
                      <span class="grey--text text--darken-1 mr-3">취급품목</span>
                      <span class="primary--text" style="font-weight: 600;">{{ article.items }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="text-body-2 ma-0 pa-0">
                    <v-col class="ma-0 px-2 py-1">
                      <span class="grey--text text--darken-1 mr-3">계좌번호</span>
                      <span>{{ article.bankAccount }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <v-divider></v-divider>
                  <v-row
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <!-- 좌측 하단 -->
                    <!-- @: 구매내역 -->
                    <v-col cols="12" class="mb-2 pt-3 pb-1 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">구매내역</span>
                          <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                            <v-icon
                              small
                              @click="popPurchase(null)"
                              class="pb-1"
                            >mdi-plus-box-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <span class="text-body-2">
                            ￦{{ numberFormat(buyTotal) }}
                          </span>
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" small class="mb-1 ml-2" color="primary" style="cursor:pointer;">mdi-help-circle-outline</v-icon>
                            </template>
                            <span class="text-body-2">구매내역 중 [주문+수령+교환] 합산금액</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <template v-for="(item, i) in purchases">
                        <v-row
                          :key="i"
                          no-gutters
                        >
                          <v-col cols="12" xs="12" sm="12">
                            <v-card
                              elevation="0"
                              class="mt-1"
                            >
                              <v-card-text class="pa-1 text--primary">
                                <v-hover v-slot:default="{ hover }">
                                  <div>
                                    <div class="mb-1 px-1">
                                      <v-icon x-small
                                        v-if="item.isStar"
                                        class="mr-1 pb-1"
                                        color="amber darken-1"
                                      >mdi-star</v-icon>
                                      <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                      <v-chip
                                        label outlined x-small class="ml-1 px-1" color="indigo"
                                        style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                      >
                                        {{ buyStat2(item.status2) }}
                                      </v-chip>
                                      <span v-show="item.str3" class="ml-1" style="font-weight: 550;">{{ item.str3 }}</span>
                                      {{ item.view1 }}
                                      <span v-show="item.str2" class="ml-1">￦{{ numberFormat(item.str2) }}</span>
                                      <span v-show="item.status" class="ml-1 primary--text">#{{ buyStat1(item.status) }}</span>
                                        <!-- @: 수정 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="popPurchase(item)"
                                          >mdi-pencil</v-icon>
                                        </template>
                                        <span>수정</span>
                                      </v-tooltip>
                                      <!-- @: 클립보드 복사 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="doCopy(item.copyText)"
                                          >mdi-content-copy</v-icon>
                                        </template>
                                        <span>클립보드복사</span>
                                      </v-tooltip>
                                      <!-- @: 댓글 추가 아이콘 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showCommentPop"
                                          >mdi-comment-text-outline</v-icon>
                                        </template>
                                        <span>댓글쓰기</span>
                                      </v-tooltip> -->
                                      <!-- @: 첨부파일 추가 아이콘 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showFilePop"
                                          >mdi-paperclip</v-icon>
                                        </template>
                                        <span>첨부파일</span>
                                      </v-tooltip> -->
                                      <!-- @: 공유링크 추가 아이콘 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showShareLinkPop"
                                          >mdi-link-plus</v-icon>
                                        </template>
                                        <span>공유링크</span>
                                      </v-tooltip> -->
                                    </div>
                                    <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                      {{ item.str1 }}
                                    </div>
                                  </div>
                                </v-hover>
                                <!-- 사용안함:[2021.7.16] 댓글 & 첨부파일 & 공유링크 리스트 -->
                                <div
                                  v-for="(cmtf, i) in item.subs"
                                  :key="i"
                                  class="my-1 text-body-2"
                                >
                                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                                    <div class="ma-0 pa-0">
                                      <v-icon
                                        :color="cmtf.isAnotherWrite ? 'purple' : ''"
                                        x-small
                                      >
                                        {{ cmtf.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                                      </v-icon>
                                      <v-icon v-show="cmtf.type === 36" small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                                      <v-icon v-show="cmtf.type === 38" small :color="mdiLinkShareIconColor">mdi-link-plus</v-icon>
                                      <!-- <code
                                        v-show="cmtf.type === 36 || cmtf.type === 38"
                                        class="mx-1 px-0 grey--text text--darken-2"
                                        style="letter-spacing: 0.08em"
                                      >
                                        {{ cmtf.gubun2 }}
                                      </code> -->
                                      <v-btn
                                        v-show="cmtf.type === 36 || cmtf.type === 38"
                                        x-small tile depressed class="mx-1 px-1 blue--text text--darken-4"
                                        color="blue lighten-4"
                                        style="font-size: 0.755rem !important;font-weight: 500;letter-spacing: 0.08em;cursor: default;"
                                      >
                                        {{ cmtf.gubun2 }}
                                      </v-btn>
                                      {{ cmtf.str1 }}
                                      <span class="text-caption grey--text">({{ strDateFormat2(cmtf.createdAt) }} {{ cmtf.wname }})</span>
                                      <!-- 사용안함: 첨부파일 다운로드 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiDownloadButtonColor"
                                            class="ml-3"
                                            @click="fileDownload(cmtf)"
                                          >mdi-cloud-download-outline</v-icon>
                                        </template>
                                        <span>다운로드</span>
                                      </v-tooltip> -->
                                      <!-- 첨부파일 바로보기 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiOpenFileButtonColor"
                                            class="ml-3"
                                            @click="fileDirectView(cmtf)"
                                          >mdi-eye-outline</v-icon>
                                        </template>
                                        <span>바로보기</span>
                                      </v-tooltip>
                                      <!-- 첨부파일 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteFile(cmtf.id)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                      <!-- 댓글 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 37"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteComment(cmtf.id)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                      <!-- 공유링크 바로가기 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 38"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiPlusBoxOutlineButtonColor"
                                            class="ml-3"
                                            @click="shareLinkDirectGo(cmtf)"
                                          >mdi-launch</v-icon>
                                        </template>
                                        <span>바로가기</span>
                                      </v-tooltip>
                                      <!-- 공유링크 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 38"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteShareLink(cmtf.id)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                    </div>
                                  </v-hover>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                    <!-- @: 정산내역 -->
                    <v-col cols="12" class="mb-2 pt-3 pb-1 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">정산내역</span>
                          <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                            <v-icon
                              small
                              @click="popSettlement(null)"
                              class="pb-1"
                            >mdi-plus-box-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <!-- <span
                            v-show="lazySettelment"
                            class="mr-2 error--text"
                            style="font-size: 0.825rem !important;font-weight: 450;"
                          >정산지체</span> -->
                          <span class="text-body-2">
                            ￦{{ numberFormat(settleTotal) }}
                          </span>
                          <!-- <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" small class="mb-1 ml-2" color="primary" style="cursor:pointer;">mdi-help-circle-outline</v-icon>
                            </template>
                            <span class="text-body-2">정산내역 중 [예정+정산] 합산금액</span>
                          </v-tooltip> -->
                        </v-col>
                      </v-row>
                      <template v-for="(item, i) in settlements">
                        <v-row
                          :key="i"
                          no-gutters
                        >
                          <v-col cols="12" xs="12" sm="12">
                            <v-card
                              elevation="0"
                              class="mt-1"
                            >
                              <v-card-text class="pa-1 text--primary">
                                <v-hover v-slot:default="{ hover }">
                                  <div>
                                    <div class="mb-1 px-1">
                                      <v-icon x-small
                                        v-if="item.isStar"
                                        class="mr-1 pb-1"
                                        color="amber darken-1"
                                      >mdi-star</v-icon>
                                      <span class="text--primary" style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                      <!-- <span v-show="item.status2" class="ml-1">[{{ settleStat2(item.status2) }}]</span> -->
                                      <v-chip
                                        label outlined x-small class="ml-2 mr-1 mb-1 px-1"
                                        :color="status2Colors[item.status2]"
                                        style="font-size: 0.70rem;font-weight: 550;letter-spacing: 0.10em"
                                      >
                                        {{ settleStat2(item.status2) }}
                                      </v-chip>
                                      <span v-show="item.str2" class="mx-1">￦{{ numberFormat(item.str2) }}</span>
                                      <span v-show="item.view2" class="mr-1 font-weight-bold error--text" style="font-size: 0.825rem !important;font-weight: 450;">{{ item.view2 }}</span>
                                      <span v-show="item.status" class="mr-1 font-weight-bold primary--text">#{{ settleStat1(item.status) }}</span>
                                      <!-- @: 수정 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="popSettlement(item)"
                                          >mdi-pencil</v-icon>
                                        </template>
                                        <span>수정</span>
                                      </v-tooltip>
                                      <!-- @: 클립보드 복사 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="doCopy(item.copyText)"
                                          >mdi-content-copy</v-icon>
                                        </template>
                                        <span>클립보드복사</span>
                                      </v-tooltip>
                                      <!-- @: 댓글 추가 아이콘 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showCommentPop"
                                          >mdi-comment-text-outline</v-icon>
                                        </template>
                                        <span>댓글쓰기</span>
                                      </v-tooltip> -->
                                      <!-- @: 첨부파일 추가 아이콘 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showFilePop"
                                          >mdi-paperclip</v-icon>
                                        </template>
                                        <span>첨부파일</span>
                                      </v-tooltip> -->
                                      <!-- @: 공유링크 추가 아이콘 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showShareLinkPop"
                                          >mdi-link-plus</v-icon>
                                        </template>
                                        <span>공유링크</span>
                                      </v-tooltip> -->
                                    </div>
                                    <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                      {{ item.str1 }}
                                    </div>
                                  </div>
                                </v-hover>
                                <!-- 사용안함:[2021.7.16] 댓글 & 첨부파일 & 공유링크 리스트 -->
                                <div
                                  v-for="(cmtf, i) in item.subs"
                                  :key="i"
                                  class="my-1 text-body-2"
                                >
                                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                                    <div class="ma-0 pa-0">
                                      <v-icon
                                        :color="cmtf.isAnotherWrite ? 'purple' : ''"
                                        x-small
                                      >
                                        {{ cmtf.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                                      </v-icon>
                                      <v-icon v-show="cmtf.type === 36" small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                                      <v-icon v-show="cmtf.type === 38" small :color="mdiLinkShareIconColor">mdi-link-plus</v-icon>
                                      <!-- !! 첨부파일, 공유링크 카테고리 태그(attach file category tag) 컴포넌트 -->
                                      <attach-file-category-tag
                                        v-show="cmtf.type === 36 || cmtf.type === 38"
                                        :caption="cmtf.gubun2"
                                      ></attach-file-category-tag>
                                      {{ cmtf.str1 }}
                                      <span class="text-caption grey--text">({{ strDateFormat2(cmtf.createdAt) }} {{ cmtf.wname }})</span>
                                      <!-- 사용안함: 첨부파일 다운로드 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiDownloadButtonColor"
                                            class="ml-3"
                                            @click="fileDownload(cmtf)"
                                          >mdi-cloud-download-outline</v-icon>
                                        </template>
                                        <span>다운로드</span>
                                      </v-tooltip> -->
                                      <!-- 첨부파일 바로보기 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiOpenFileButtonColor"
                                            class="ml-3"
                                            @click="fileDirectView(cmtf)"
                                          >mdi-eye-outline</v-icon>
                                        </template>
                                        <span>바로보기</span>
                                      </v-tooltip>
                                      <!-- 첨부파일 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteFile(cmtf.id)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                      <!-- 댓글 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 37"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteComment(cmtf.id)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                      <!-- 공유링크 바로가기 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 38"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiPlusBoxOutlineButtonColor"
                                            class="ml-3"
                                            @click="shareLinkDirectGo(cmtf)"
                                          >mdi-launch</v-icon>
                                        </template>
                                        <span>바로가기</span>
                                      </v-tooltip>
                                      <!-- 공유링크 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 38"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteShareLink(cmtf.id)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                    </div>
                                  </v-hover>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <v-card
                    elevation="0"
                    class="my-12"
                  >
                    <div>&nbsp;</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-divider vertical class="ma-0 pa-0"></v-divider>

            <!-- 우측 컨텐츠 시작 --->
            <v-col>
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- @: 추가정보 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-body-2 grey--text text--darken-1 mr-3">추가정보</span>
                    <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                      <v-icon
                        small
                        @click="editTxt1"
                        class="pb-1"
                      >mdi-plus-box-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-3 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 grey--text text--darken-2 pa-1"
                      elevation="0"
                      max-height="150"
                      :outlined="article.txt1 ? true : false"
                    >
                      <div id="vue2editorViewer" v-html="article.txt1"></div>
                    </v-card>
                  </v-col>

                  <!-- @: 전자결재 -->
                  <v-col cols="12" class="mt-5 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">전자결재</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="addEapproval"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ relElApprovals.length }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relElApprovals">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="my-0 pa-0">
                          <v-hover v-slot:default="{ hover }">
                            <v-card
                              elevation="0"
                              class="ma-0"
                            >
                              <v-card-text class="pa-1 text--primary">
                                <div
                                  :elevation="hover ? 3 : 0"
                                >
                                  <div class="mb-0 px-1">
                                    <v-icon small
                                      v-if="item.isStar"
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <strong>{{ strDateFormat3(item.updatedAt) }}</strong>
                                    <span class="ml-2 font-italic font-weight-bold primary--text">{{ item.gubun1 }}</span>
                                    <!-- <v-chip label x-small color="black" class="mx-2 mb-1 px-1" outlined>{{ item.gubun1 }}</v-chip> -->
                                    <!-- 주의: 임시저장 일때는 내가 기안한 것만 보임 -->
                                    <v-tooltip
                                      v-if="item.status === 1"
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-on="on"
                                          v-show="hover && item.isMine"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="goRelEapproval(item)"
                                        >mdi-launch</v-icon>
                                      </template>
                                      <span>바로가기</span>
                                    </v-tooltip>
                                    <!-- 주의: 임시저장이 아닐때는 남의것도 보임 -->
                                    <v-tooltip
                                      v-else
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-on="on"
                                          v-show="hover"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="goRelEapproval(item)"
                                        >mdi-launch</v-icon>
                                      </template>
                                      <span>바로가기</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                                <div class="py-0 pl-1 text--primary">
                                  {{ item.subject }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- !! 거래처 수정 팝업 -->
    <shop-add-dialog ref="shopAddDialog"></shop-add-dialog>
    <!-- 추가정보 -->
    <edit-txt1-dialog ref="editTxt1" :article="article"></edit-txt1-dialog>
    <!-- !! 구매내역 등록/수정 팝업 -->
    <purchase-dialog ref="purchaseDialog" :article="article"></purchase-dialog>
    <!-- !! 정산내역 등록/수정 팝업 -->
    <settle-dialog ref="settleDialog" :article="article"></settle-dialog>
    <!-- 댓글 팝업 -->
    <add-comment-pop ref="addCommentPop"></add-comment-pop>
    <!-- 첨부파일 팝업 -->
    <add-file-pop ref="addFilePop"></add-file-pop>
    <!-- 공유링크 팝업 컴포넌트 -->
    <add-share-link-pop ref="addShareLinkPop"></add-share-link-pop>

  </v-container>
</template>

<script>
// @: filters
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat2 from '@/filters/strDateFormat2'
import strDateFormat3 from '@/filters/strDateFormat3'
import numberFormat from '@/filters/numberFormat'
import buyStat1 from '@/filters/buyStat1' // 구매내역 > 구매상태
import buyStat2 from '@/filters/buyStat2' // 구매내역 > 거래형태
import settleStat1 from '@/filters/settleStat1' // 정산내역 > 정산상태
import settleStat2 from '@/filters/settleStat2' // 정산내역 > 영수증 발행 상태

// @: dialogs
import shopAddDialog from '@/components/supply/shopAddDialog' // !! 거래처 수정창
import editTxt1Dialog from '@/components/supply/shopTxt1Dialog' // !! 거래처 추가정보
import purchaseDialog from '@/components/supply/purchaseDialog' // !! 구매내역 팝업
import settleDialog from '@/components/supply/settleDialog' // !! 정산내역 팝업

// @: popups
import addCommentPop from '@/components/supply/addCommentPop' // 댓글 팝업
import addFilePop from '@/components/supply/addFilePop' // 첨부파일 팝업
import addShareLinkPop from '@/components/supply/addShareLinkPop' // 공유링크 팝업

// @: tag components
import attachFileCategoryTag from '@/components/tags/attachFileCategoryTag' // 첨부파일,공유링크 카테고리 태그

// !! 현재 dir
const currDir = 'shop'

export default {
  components: {
    shopAddDialog,
    editTxt1Dialog,
    purchaseDialog,
    settleDialog,
    addCommentPop,
    addFilePop,
    addShareLinkPop,
    attachFileCategoryTag
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 게시물 데이터
    articleId: 0,
    article: {},
    // 구분: 대표자와 담당자명을 담는 변수
    viewCompanyMan: '',
    // 구분: 서브 컨텐츠 리스트
    purchases: [], // 구매내역
    settlements: [], // 정산내역
    relCommentAndFiles: [], // 댓글 + 첨부파일
    relElApprovals: [], // 전자결재
    // 구분: 합산금액 표시용 변수
    buyTotal: 0, // 구매내역 합산액(주문+수령+교환)
    settleTotal: 0, // 정산내역 합산액
    // 구분: 정산지체 표시
    lazySettelment: false,
    // 구분: 첨부파일
    overlay: false, // 첨부파일 다운로드 로딩시 오버레이
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 영수증여부 태그 색상
    status2Colors: {
      1: 'error', // 미발행
      2: 'primary', // 세금계산서
      3: 'green darken-1', // 현금영수증
      4: 'purple' // 카드결제
    }
  }),

  watch: {
    // !!중요: /shop 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      // console.log(`watch.route`)
      // console.log(to.params)
      // !! 넘겨준 파라미터가 있는 경우. 등록시엔 -R을 제거해야 한다.
      const paramIds = to.params.id.split('-')
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 수정시엔 '-E' 가 붙어서 넘어온다
        this.articleId = parseInt(paramIds[0], 10)
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        this.articleId = parseInt(paramIds[0], 10)
        // 넘어온 id로 해당 게시물 정보를 가져와야 한다
        this.getArticle(this.articleId)
      }
    }
  },

  computed: {
  },

  mounted () {
    // console.log(`mounted.route`)
    // console.log(this.$route.params)

    // 중요: 넘어온 id를 매칭시켜야 한다!
    // 등록시엔 '-R' 이 붙어오므로 분리해서 적용한다. 안그러면 뷰페이지에서 목록으로 보낼때마다 초기화 해버린다.
    const paramIds = this.$route.params.id.split('-')
    this.articleId = parseInt(paramIds[0], 10)

    // 넘어온 id로 해당 게시물 정보를 가져와야 한다
    this.getArticle(this.articleId)
  },

  methods: {
    strDateFormat,
    strDateFormat2,
    strDateFormat3,
    numberFormat,
    buyStat1, // 구매내역 > 구매상태
    buyStat2, // 구매내역 > 거래형태
    settleStat1, // 정산내역 > 정산상태
    settleStat2, // 정산내역 > 영수증 발행 상태
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 목록으로 - 주의: shop 보내는 것 주의
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    // 구분: id로 게시물을 가져온다
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const { data } = await this.$axios.get(`lawork/lwc/getShopById/${id}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          this.article = data.article
          // 대표자 | 담당자 - 이름을 변수에 담자
          this.viewCompanyMan = [ this.article.ceoName, this.article.director ].filter(c => c).join(' | ')
          // 중요: 구매내역/정산내역(댓글,첨부파일),전자결재 목록 패칭
          await this.getSubContents() // 초기엔 모든 서브 컨텐츠 패칭
          // 중요: 전자결재(5)는 따로 패칭해야 한다.
          await this.getRelEaByIdType()
          //
        } else {
          // throw new Error('존재하지 않는 데이터 입니다!')
          this.isId = false
          this.overlay = true
          setTimeout(this.goList, 1000)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 거래처 수정
    async editArticle () {
      try {
        const result = await this.$refs.shopAddDialog.open('거래처', { width: 650, height: 600 }, this.articleId, this.article)
        // !! 정상적인 수정이 완료되면 등록된 아이디를 리턴받아서 뷰페이지로 보낸다.
        if (result) {
          // 중요: 수정시엔 리프레시를 의미하는 '-E' 을 붙여서 보낸다.
          this.refreshList(`${result}-E`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 삭제
    async deleteArticle () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`/lawork/lwc/deleteShop/${this.articleId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          this.refreshList(`LR`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 추가정보 팝업 띄우기
    async editTxt1 () {
      try {
        if (await this.$refs.editTxt1.open('추가정보', { width: 750 })) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 구매내역 등록/수정 팝업
    // !![2021.7.16] width 650 -> 550 으로 줄인다.
    async popPurchase (item) {
      try {
        let result = null
        if (item) { // 수정 모드
          result = await this.$refs.purchaseDialog.open('구매내역', { width: 550 }, 1, item)
          // !! 리스트 리프레시 -- 수정창을 열고 삭제한 경우도 여기로 오므로 리스트는 리프레시 된다!
          // this.refreshList(`${this.articleId}-E`)
        } else { // 등록 모드
          result = await this.$refs.purchaseDialog.open('구매내역', { width: 550 }, -1, null)
          // this.refreshList(`${this.articleId}-E`) // !! 리스트 리프레시
        }

        if (result.type === 'addSettlement') {
          // @: 결과가 정산내역추가인 경우 - 정산내역창을 열어야 한다
          if (result.data) { // 리턴된 데이터가 있으면 정산내역창에 같이 보낸다!
            // 약간의 딜레이를 주고 정산내역창을 연다
            setTimeout(() => {
              this.popSettlement(null, result.data)
            }, 500)
          }
          //
        } else {
          // 수정성공이나 삭제의 경우
          // this.refreshList(`${this.articleId}-E`)
          //
        }
        this.refreshList(`${this.articleId}-E`)
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정산내역 등록/수정 팝업
    async popSettlement (item, rData) {
      try {
        let index = item ? 1 : -1 // 수정인 경우 1, 등록인 경우 -1
        if (await this.$refs.settleDialog.open('정산내역', { width: 550 }, index, item, rData)) {
          // !! 리스트 리프레시 -- 수정창을 열고 삭제한 경우도 여기로 오므로 리스트는 리프레시 된다!
          this.refreshList(`${this.articleId}-E`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 중요: 넘어온 타입과 리스트로 리스트 정리 작업 패칭
    async setSubContentList ({ type, list }) {
      try {
        // 합계금액 초기화
        if (type === 47) this.buyTotal = 0 // 구매내역 합산
        if (type === 48) this.settleTotal = 0 // 정산내역 합산

        if (list.length > 0) {
          list.map(item => {
            if (type === 47) {
              // !! 구매내역 - purchases
              // @: 총합
              if (item.str2) {
                if (!(item.status === 3 || item.status === 4)) { // !![2021.10.21 추가] 취소, 반품은 합산에서 제외!
                  this.buyTotal += parseInt(item.str2, 10)
                }
              }

              item.view1 = ''
              let view1Items = []
              let _ias = []

              // if (item.str2) { // 가격
              //   view1Items.push(`￦${numberFormat(item.str2)}`)
              // }

              // 물품,규격,개수를 한꺼번에 처리
              // if (item.str3) _ias.push(item.str3) // 물품
              if (item.str4) _ias.push(item.str4) // 규격
              if (item.str5) _ias.push(item.str5) // 개수
              if (_ias.length > 0) {
                view1Items.push(_ias.join('*'))
              }

              if (view1Items.length > 0) {
                item.view1 = `(${view1Items.join(' ')})`
              }

              // @: 클립보드에 복사할 텍스트 만들기
              item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} [${buyStat1(item.status)}] ${item.str3} ${item.view1} ￦${numberFormat(item.str2)} ${item.str1 ? `- ${item.str1}` : ''} [${buyStat2(item.status2)}]`
              //
            } else if (type === 48) {
              // !! 정산내역 - settlements
              this.lazySettelment = false // 정산지체 초기화

              // @: 총합
              if (item.str2) {
                this.settleTotal += parseInt(item.str2, 10)
              }

              item.view1 = ''
              let view1Items = []

              // @: 정산지체 처리
              item.view2 = ''
              if (item.status === 1) { // 예정인 경우
                if (this.$moment().diff(item.date1, 'days') > 0) { // 오늘을 기준으로 뺐을 때 0보다 크면 지난 것이다.
                  item.view2 = '정산지체'
                  this.lazySettelment = true // 정산지체 표시
                }
              }

              // 정산금액
              // if (item.str2) {
              //   view1Items.push(`(${numberFormat(item.str2)}원)`)
              // }

              // 세금계산서/영수증 발행여부
              view1Items.push(settleStat2(item.status2))

              if (view1Items.length > 0) {
                item.view1 = `[${view1Items.join(' ')}]`
              }

              // @: 클립보드에 복사할 텍스트 만들기
              item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} ${item.view1} ￦${numberFormat(item.str2)} ${item.view2 ? `${item.view2} ` : ''}${item.str1 ? `- ${item.str1}` : ''} [${settleStat1(item.status)}]`
              //
            } else if (type === 28) {
              // !! 전자결재 - 사용안함: relElApprovals
              //
            }
          })
        }
        return list
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 중요: 서브 컨텐츠 패칭 처리 메소드
    async getSubContents (type = null) {
      try {
        const columns = { shopId: this.articleId, type } // !! 거래처아이디, 타입
        const { data } = await this.$axios.post(`lawork/lwc/getContentsOfShop`, columns)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // console.log(data.list)

        let rels = [
          { type: 47, name: 'purchases' }, // 구매내역
          { type: 48, name: 'settlements' } // 정산내역
        ]

        // 넘어온 타입이 있는 경우
        if (type) {
          const [ r ] = rels.filter(c => c.type === type)
          const list = data.list.filter(c => c.type === r.type)
          this[r.name] = await this.setSubContentList({ type: r.type, list })
        } else { // 넘어온 타입이 없다(전체)
          rels.forEach(async (r) => {
            const list = data.list.filter(c => c.type === r.type)
            this[r.name] = await this.setSubContentList({ type: r.type, list })
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 구분: 연관된 전자결재 목록 패칭
    async getRelEaByIdType () {
      try {
        const rId = this.articleId
        const rType = 46 // 주의: 거래처 타입은 46
        const { data } = await this.$axios.get(`lawork/eapprove/getRelEaByIdType/${rId}/${rType}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.relElApprovals = data.list
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 전자결재 등록하기
    async addEapproval () {
      try {
        // !! confirm 창으로 전자결재에 등록하겠냐고 물어봐야겠지?
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `전자결재를 작성 하시겠습니까?`
        if (await pop.open('확인', msg, { color: 'info', width: 400 })) {
          const params = {
            id: 'new', // '/eawriting/new' 로 이동시킨다.. 전자결재를 작성한다!
            rType: 46, // 거래처의 타입
            rId: this.article.id,
            rInfo: `${this.article.name}${this.article.coNum ? `(${this.article.coNum})` : ''}`,
            rTid: this.article.teamId
          }
          // console.log(params)
          this.$router.push({ name: 'eawriting', params })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연관된 전자결재로 바로가기
    async goRelEapproval (approval) {
      try {
        if (!approval) throw new Error('결재로 이동할 수 없습니다.')

        if (approval.status === 1) {
          // 임시저장인 경우 작성함으로
          this.$router.push(`/eawriting/${approval.id}`)
        } else {
          // 임시저장 이외에는 결재함으로 간다.
          this.$router.push(`/ealist/${approval.id}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    doCopy (copyText) {
      try {
        // console.log(copyText)
        let store = this.$store
        this.$copyText(copyText)
          .then(function (e) {
            // console.log(e)
            store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
          }, function (e) {
            throw new Error(`복사하지 못했습니다. ${e}`)
          })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 이메일 쓰기(지메일) 바로가기
    async writeEmail (email) {
      try {
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${email}` // 쓰기로 바로간다.
        // const gmailUrl = `https://mail.google.com/mail/u/0/#inbox?compose=new` // 쓰기로 바로간다.
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 홈페이지 바로가기
    async goSite (homepage) {
      try {
        let homepageUrl = ''
        if (homepage.indexOf('http://') !== -1 || homepage.indexOf('https://') !== -1) {
          homepageUrl = homepage
        } else {
          homepageUrl = `https://${homepage}`
        }
        const link = document.createElement('a')
        link.href = homepageUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 댓글 처리 ----
    // 댓글 팝업 열기
    async showCommentPop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addCommentPop.showMenu) {
          await this.$refs.addCommentPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addCommentPop.setData(item)
          if (result) {
            await this.getSubContents() // 리프레시
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 댓글 삭제
    async deleteComment (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/client/deleteLwcommon/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          await this.getSubContents() // 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 처리 ---
    async showFilePop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addFilePop.showMenu) {
          await this.$refs.addFilePop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addFilePop.setData(item)
          if (result) {
            await this.getSubContents() // 리프레시
          }
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 첨부파일 삭제
    async deleteFile (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>관리자의 구글 드라이브에는 유지됩니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/client/deleteLwcommon/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          await this.getSubContents() // 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 다운로드
    async fileDownload (file) {
      try {
        if (!file.str5) throw new Error(`유효한 파일이 아닙니다.`)
        this.overlay = true // 로딩 오버레이 on
        const webContentLink = file.str5 // 이건 다운로드

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우는 그냥 다운로드
          document.location.href = webContentLink // 다운로드 시작!
          // 2초간 로딩 딜레이
          setTimeout(() => {
            this.overlay = false // 로딩 오버레이 off
          }, 2000)
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/client/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            document.location.href = webContentLink // 다운로드 시작!
            // 2초간 로딩 딜레이
            setTimeout(() => {
              this.overlay = false // 로딩 오버레이 off
            }, 2000)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/case/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공유링크 처리 ---
    async showShareLinkPop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addShareLinkPop.showMenu) {
          await this.$refs.addShareLinkPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addShareLinkPop.setData(item)
          if (result) {
            await this.getSubContents() // 리프레시
          }
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 구분: 공유링크 바로가기 처리
    async shareLinkDirectGo (item) {
      try {
        // console.log(item)
        if (!item.str2) throw new Error(`유효한 공유주소가 아닙니다.`)

        // * [2022.6.7 수정] http(s):// 가 없는 경우 자동으로 붙여준다.
        let sLink = item.str2
        let httpRegex = /^(http(s)?:\/\/)/
        if (!httpRegex.test(String(item.str2).toLowerCase())) {
          sLink = 'https://' + sLink
        }

        // 다른 탭에서 링크 열기
        const link = document.createElement('a')
        link.href = sLink
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공유링크 삭제
    async deleteShareLink (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/case/deleteLwcommon/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          await this.getSubContents() // 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
